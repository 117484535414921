@import url('https://rsms.me/inter/inter.css');

:root {
  font-size: 2.22vmin;

  --c-white: #fff;
  --c-black: #212121;

  --c-bg: var(--c-white);
  --c-inverted: var(--c-black);

  @media (prefers-color-scheme: dark) {
    --c-bg: var(--c-black);
    --c-inverted: var(--c-white);
  }

  font-family: 'Inter', sans-serif;
}

body {
  background: var(--c-bg);
  line-height: 1;
  color: var(--c-inverted);
}

svg  {
  width: 1em;
  height: 1em;
  display: block;
  fill: var(--c-bg);
}

.save {
  display: block;
  width: 100%;
  height: auto;
  image-rendering: pixelated;
  background: var(--c-bg);
}

.app {
  position: relative;
  width: 90vmin;
  margin: 10vmin auto;
  display: flex;
  flex-direction: row-reverse;
}

.cubes {
  //background: var(--c-inverted);

  height: 80vmin;
  width: 80vmin;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 0vw;
  box-shadow: 0 0 0 4px var(--c-inverted);

  background-color: var(--c-bg);
  background-image: linear-gradient(transparent calc(100% - 1px), rgba(#000, .1)), linear-gradient(to right, transparent calc(100% - 1px), rgba(#000, .1));
  background-size: 12.5% 12.5%;

  @media (prefers-color-scheme: dark) {
    background-image: linear-gradient(transparent calc(100% - 1px), rgba(#fff, .5)),
    linear-gradient(to right, transparent calc(100% - 1px), rgba(#fff, .5));
  }
}


.outlined .cube::after {
  box-shadow: inset 0 0 0 2px var(--c-bg);
}
.outlined .cube i {
  box-shadow: inset 0 0 0 4px var(--c-bg);
}

.cube {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: var(--bg);

  &::after {
    pointer-events: none;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    height: 101%;
    width: 101%;
    transform: translate(-50%, -50%) scale(1);
    background-color: var(--bg);
    transition: 200ms transform;
  }


  .view--pixels & {
    background-color: transparent;

    &::after {
      transform: translate(-50%, -50%) scale(.5);
    }
  }

  .view--itten & {
    &::after {
      z-index: 3;
      transform: translate(-50%, -50%) scale(.75);
    }
  }
  .view--pattern &__inner {
    width: 200%;
    height: 200%;
    i {
      transform: scaleY(.5);
      border-radius: 50%;
    }
  }

  .view--grid &__inner {
    width: 200%;
    height: 200%;
    transform: translate(-50%, -50%);
  }

  &__inner {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;

    height: 14.142135623730951vmin; // (80vw / 8) = 10 => Math.sqrt(Math.pow(10, 2) + Math.pow(10, 2))
    width: 14.142135623730951vmin;
    flex-wrap: wrap;
    transform: translate(-50%, -50%) rotate(-45deg);
    pointer-events: none;


    i {
      display: block;
      height: 50%;
      flex: 1 1 50%;
      transition: 233ms background-color linear,
                  333ms transform cubic-bezier(.7, .3, 0, 1),
                  333ms border-radius cubic-bezier(.7, .3, 0, 1);
      transform: scale(1.01);

      //will-change: transform, border-radius;

      .view--cubes--small & {
        transform: scale(.5);
      }

      .view--pixels & {
        transform: scale(.71) rotate(45deg);
      }

      .view--circles & {
        border-radius: 50%;
        transform: scale(1.4);
        mix-blend-mode: overlay; //multiply
      }

      .view--circles--small & {
        border-radius: 50%;
        transform: scale(0.5);
      }



      &.top {
        background: var(--mix-top);
      }

      &.right {
        background: var(--mix-right);
      }

      &.bottom {
        background: var(--mix-bottom);
      }

      &.left {
        background: var(--mix-left);
      }
    }
  }
}

.color-input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  font-size: 2rem;

  .mode--edit .filled & {
    display: block;
  }
}


.ellogo {
  z-index: 4;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: calc(80vmin/8);
  width: calc(80vmin/8 * 2);
  box-sizing: border-box;
  padding: .9rem;

  svg {
    display: block;
    height: 100%;
    width: 100%;
    overflow: visible;

    path {
      fill: var(--c-inverted);
    }
    path:nth-child(2) {
      stroke: var(--c-inverted);
      fill: none;
      transition: 600ms transform cubic-bezier(.7,.3,0,1.3);
    }
  }
  transform: rotate(90deg) translateX(-100%);
  transform-origin: 0 100%;

  &:hover {
    path:nth-child(2) {
      stroke: pink;
      transform: translate(80vmin, 40vmin)  scale(-2.5);
    }
  }

}
.tools {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0 4px var(--c-inverted);
  width: calc(80vmin/8);
  height: 80vmin;
  background: var(--c-bg);

  menu {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  li {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    flex: 0 0 12.5%;
    background: var(--c-inverted);
  }
}

.tools__group {
  position: relative;

  .view-modes {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    height: 80vmin;
    width: 100%;
    top: -400%;
    z-index: 4;
    transition: 300ms opacity;
  }
}


.icon--comp {
  position: relative;
}
.button--views .icon--comp i {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2.5rem;
  width: auto;
  height: auto;
  text-shadow: 3px 3px 0 var(--c-inverted);
  font-style: normal;
  line-height: 1;

  &:nth-child(1) {
    transform: translate(-50%, -70%);
    z-index: 2;
  }

  &:nth-child(2) {
    z-index:1;
    transform: translate(-90%, -30%);
  }

  &:nth-child(3) {
    transform: translate(-10%, -40%);
  }
}

.button--views {
  z-index: 5;
}

.button--outlined {
  z-index: 6;
}

.button--views[aria-expanded] + .view-modes {
  opacity: 1;
}

.button--views[aria-expanded] {
  .icon--comp {
    opacity: 0;
  }
  &::after {
    font-size: 3.5rem;
    content: '×';
    color: var(--c-bg);
  }
}

.main-menu .view-modes li {
  transform: translate(0, 0);
  transition: 200ms opacity,
    200ms transform cubic-bezier(.7, .3, 0, 1) 100ms;
  pointer-events: none;
  /*
  &:nth-child(4) {
    transform: translate(0, 0);
  }

  &:nth-child(3) {
    transform: translate(0, 100%);
  }

  &:nth-child(5) {
    transform: translate(0, -100%);
  }

  &:nth-child(2) {
    transform: translate(0, 200%);
  }

  &:nth-child(6) {
    transform: translate(0, -200%);
  }

  &:nth-child(1) {
    transform: translate(0, 300%);
  }

  &:nth-child(7) {
    transform: translate(0, -300%);
  }

  &:nth-child(8) {
    transform: translate(0, -400%);
  }*/
}

.subnav-visible .main-menu .view-modes li {
  opacity: 1;
  pointer-events: all;
  transform: translate(calc(100% + 4px), 0);
  transition: 300ms opacity 100ms,
    200ms transform cubic-bezier(.7, .3, 0, 1);

  &:nth-child(4) {
    transition-delay: 100ms, 0s;
  }
  &:nth-child(3),
  &:nth-child(5) {
    transition-delay: 150ms, 50ms;
  }

  &:nth-child(2),
  &:nth-child(6) {
    transition-delay: 200ms, 100ms;
  }

  &:nth-child(1),
  &:nth-child(7) {
    transition-delay: 250ms, 100ms;
  }

  &:nth-child(8) {
    transition-delay: 300ms, 200ms;
  }
}

@media (orientation:portrait) {
  .subnav-visible .main-menu .view-modes li {
    transform: translate(0, calc(-100% - 4px));
  }
}

button,
select {
  position: absolute;
  display: block;
  appearance: none;
  border: none;
  background: var(--c-inverted);
  color: var(--c-bg);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 200ms;
  outline: none;
}

select {
  opacity: 0;
  padding: 1rem;
  font-size: 2rem;
}

.icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  font-size: 2rem;
  pointer-events: none;

  &--unicode {
    margin-top: -0.1em;
  }
}

.button--cubes .icon,
.button--circles .icon {
  font-size: 4rem;
}

.mode--edit .button--edit,
.mode--erase .button--erase,
.view--cubes .button--cubes,
.view--cubes--small .button--cubes-small,
.view--circles .button--circles,
.view--circles--small .button--circles-small,
.view--itten .button--itten,
.view--pixels [data-mode="pixels"],
.outlined .button--outlined,
.view--grid .button--grid,
.view--pattern .button--pattern,
.button--views[aria-expanded] {
  transform: translate(-10px, -10px);
  box-shadow: 1px 1px 0 var(--c-bg),
    2px 2px 0 var(--c-bg),
    3px 3px 0 var(--c-bg),
    4px 4px 0 var(--c-bg),
    5px 5px 0 var(--c-bg),
    6px 6px 0 var(--c-bg),
    7px 7px 0 var(--c-bg);
}


.button--outlined .icon {
  font-size: 4em;
  margin-top: -.1em;
}


.colorlist {
  width: 90vmin;
  margin: 10vmin auto;
  list-style: none;
  padding: 0;
}

.colorlist__entry {
  display: flex;
  align-items: center;

  h2, pre {
    margin: 0;
  }

  h2 {
    font-weight: 600;
    padding-bottom: 1vmin;
  }

  pre {
    font-size: .8em;
  }

  & + & {
    margin-top: 4vmin;
  }
}

.colorSwatch {
  background: var(--color);
  flex: 0 0 calc(80vmin/8);
  height: calc(80vmin/8);
  margin-right: 1rem;
  border: 4px solid currentColor;
  box-shadow: inset 0 0 0 4px #fff;
}

@media (orientation:portrait) {
  .ellogo {
    left: auto;
    transform: none;
  }

  .app,
  .colorlist {
    width: 80vmin;
    margin: 10vmin auto;
    flex-direction: column;
  }

  .tools__group .view-modes {
    height: 100%;
    top: 0;
    left: -400%;
  }

  .tools {
    height: calc(80vmin/8);
    width: 80vmin;
    display: flex;

    li {
      flex: 0 0 calc(80vmin/8);
    }

    menu {
      flex-direction: row;
      width: calc(80vmin/8 * 7);
    }

    &::after {
      left: auto;
      top: 0;
      width: calc(80vmin/8);
    }
  }

  .save {
    width: calc(80vmin/8);
    height: auto;
    flex: 0 0 calc(80vmin/8);
  }
}
